import * as Sentry from '@sentry/gatsby'
import { ExtraErrorData } from '@sentry/integrations'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  // __SENTRY_DSN__ defined in webpack from SENTRY_DSN by Gatsby plugin
  dsn: __SENTRY_DSN__,
  integrations: [
    ...(process.env.NODE_ENV !== 'development' ? [new BrowserTracing()] : []),
    new ExtraErrorData({
      // increase depth to capture response headers and body (for fetch error)
      depth: 4,
    }),
  ],
  // __SENTRY_ENVIRONMENT__ defined in webpack from SENTRY_ENVIRONMENT by gatsby-node.js
  ...(!!__SENTRY_ENVIRONMENT__ && {
    environment: __SENTRY_ENVIRONMENT__,
  }),
  sampleRate: 1.0,
  tracesSampleRate: 0.1,
})
